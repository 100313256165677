<template>
  <div class="section video-section">
    <b-container id="psiholog">
      <h2 class="text-uppercase">Do zdravih navad z znanostjo</h2>
      <div class="text-wrap">
        <h4 class="mt-2 mb-0">Spoznajte psihologa, dr. Simona Brezovarja,</h4>
        <h6 class="mt-0">
          strokovnjaka za programom ustvarjanja zdravih navad z več pameti kot
          sreče.
        </h6>
      </div>
      <hr class="green-dotted mt-4 mb-5" />
      <div class="video-container">
        <div class="embed-container">
          <iframe
            src="https://player.vimeo.com/video/686724435?h=56e049c30f"
            frameborder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div class="text-box">
        <p class="mb-4">
          <b> ‘Navada je železna srajca’ </b>
          <i
            >je eden izmed tistih pregovorov, ki so ga znanstvene raziskave
            posredno ali neposredno največkrat potrdile. Ljudje si prizadevamo,
            da bi se dobrih navad držali, “nalezli”, jih ponavljali. Vendar nam
            to velikokrat relativno slabo uspeva. Zakaj? Eno ključnih dognanj s
            področja nevroznanosti je, da so sinapse (= povezave med našimi
            živčnimi celicami) silno odporne na spremembe in da je potrebno novo
            vedenje ponoviti vsaj tisočkrat. Potem so tu še dopamin,
            nagrajevalni hormon. In štiri gonilne sile motivacije, ki odločajo o
            uspehu: cilji, nagrade, misli in čustva. In vse to bomo spoznavali
            in trenirali znotraj 30-dnevnih programov za uvajanje zdravih navad
            v naše življenje.
          </i>
        </p>
        <b-collapse id="collapse-1">
          <i>
            Eno ključnih dognanj s področja nevroznanosti je, da so sinapse,
            povezave med našimi živčnimi celicami, silno odporne za spremembo in
            da je potrebno novo vedenje ponoviti vsaj tisočkrat, da postane
            avtomatizirano. A zakaj se ljudje mnogokrat ustavimo že po nekaj
            ponovitvah in se avtomatizaciji navade ne uspemo niti približati?
            Navada je v resnici vedenjska manifestacija številnih nevrobioloških
            in duševnih procesov, ki, če ne igrajo usklajeno, kot simfonični
            orkester, navade nikoli ne bodo uspeli pobuditi, zlasti pa ne
            obdržati. Bodimo bolj konkretni. Če bi pogledali v človeške možgane,
            bi ugotovili, da se globoko v naših možganih sprožajo velike
            količine živčnega prenašalca, ki mu pravimo dopamin. Več kot uspejo
            naši možgani proizvesti dopamina, bolj dobro se počutimo ob
            aktivnostih in večja je verjetnost, da jih bomo izvajali tudi v
            bodoče.

            <br /><br />

            Dopamin je torej nekakšen nagrajevalni hormon in igra ključno vlogo
            pri motivaciji ter usmerjanju našega vedenja. Ljudje se med seboj
            razlikujemo in tako nam tudi različne aktivnosti sprožajo dopamin v
            možganih. Nekomu bo sprožala dopamin prijetna glasba, drugemu tek v
            naravi, tretjemu gledanje razstave, itn. Na škodljivem koncu spektra
            žal lahko ugotovimo, da delujejo na dopaminski sistem praktično vse
            zasvojljive substance (npr. cigarete, alkohol), zato se jim, ko
            enkrat ugrabijo dopaminski sistem, težko upremo. Ko govorimo o
            zdravih navadah pa vsekakor drži, da se številne navade ne
            avtomatizirajo, ker ne uspejo spodbuditi dopaminskih krogotokov v
            naših možganih.
            <br /><br />

            S tem pa pridemo do naših duševnih procesov, in ko govorimo o
            navadah, so ključni psihološki koncepti predvsem cilj, nagrada,
            misli in čustva, vsi ti štirje pa so gonilne sile – motivacije.
            Poglejmo si na kratko te temeljne štiri vidike motivacije v odnosu
            do navad. Številne navade se ne uspejo ‘prijeti’, ker so (i) cilji
            previsoki/nerealistični, preveč oddaljeni in premalo razdelani,
            (ii), ker jim ne sledi ustrezna nagrada, (iii) ker naše cilje
            spremljajo nerealne misli (tj. pričakovanja, ideje, prepričanja) in
            (iv) ker ob vsem tem doživljamo pretežno neprijetna čustva.
            <br /><br />

            Čeprav je določena količina neprijetnih čustev (sploh v začetnih
            fazah, ko se lotimo neke nove navade) dovoljena in pričakovana
            (recimo občutek napora, ko smo prvič na fitnes napravi ali ko
            naredimo prve tekaške korake), pa pretirano izražena neprijetna
            čustva zanesljivo zavirajo nadaljnje prakticiranje in posledično
            utrjevanje navade. Skušajmo zdaj vse skupaj povezati v smiselno
            celoto. Navada se bo uspešno začela, utrjevala in prijela le v
            primeru, da bo uspela učinkovito aktivirati dopaminski sistem v
            naših možganih, ta pa se bo aktiviral le takrat, ko bodo štirje
            jezdeci motivacije (cilj, nagrada, misel, čustvo) korakali ubrano, v
            enakomernem ritmu.
            <br /><br />

            Cilji, nagrade, misli in čustva pa lahko, ko o njih sploh ne
            razmišljamo, oziroma so popolno neustrezni (ali odsotni), kaj hitro
            postanejo štirje jezdeci apokalipse naših navad. Takšna navada
            enostavno ne bo uspela prebuditi dopaminskih vrelcev v globini naših
            možganov, posledično pa navada ne bo imela pogonskega sredstva za
            svoje utrjevanje in avtomatizacijo. Zatorej je pomembno upoštevati
            vse predstavljene vidike in jih vzeti v zakup, ko želimo v naš
            vsakdan vnesti nekaj novega ali pa iz njega odstraniti nekaj
            starega.

            <br /><br />
            Zmerna gibalna aktivnost, zdravo spanje, uravnotežena prehrana,
            sproščanje telesa in učinkovite komunikacije veščine predstavljajo
            pet življenjsko pomembnih navad, ki med drugim predstavljajo
            pomembne gradnike našega duševnega zdravja. Redki so, ki uspejo
            prakticirati in uspešno negovati vseh pet navad, nemalo pa jih ima
            vsaj občasne težave na vseh petih področjih. S petimi 30-dnevnimi
            programi želimo ljudi spodbuditi k spremembam na teh petih področjih
            ter k utrjevanju navad v njihovi zdravi različici. Vsi programi so
            utemeljeni na zgoraj predstavljenih, sodobnih dognanjih s področja
            nevroznanosti in psihologije, glavni poudarek pa je zlasti na
            motivacijskih dejavnikih, psihološkemu gorivu vseh naših navad.
          </i>
          <br />
          <br />
          <img
            class="d-none d-lg-block"
            src="../../assets/img/model-desktop1.png"
          />
          <img
            class="d-block d-lg-none model-mobile"
            src="../../assets/img/model-mobile1.png"
          />
          <br />
          <br />
          <br />
        </b-collapse>
        <b
          class="text-uppercase mb-0 cursor-pointer list-item"
          v-b-toggle.collapse-1
        >
          {{ isCollapseOpen ? "Manj" : "Več" }} o teoretičnem ozadju
          programov</b
        >
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapseOpen: false,
    };
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      this.isCollapseOpen = isJustShown;
    });
  },
};
</script>

<style>
</style>
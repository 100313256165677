<template>
  <div class="section hero">
    <div class="bg-left"></div>
    <div class="bg-right"></div>
    <div class="logo-container">
      <img src="../../assets/img/radenska-logo.png" alt="Radenska" />
    </div>
    <b-container>
      <b-row class="justify-content-center justify-content-lg-start">
        <b-col cols="0" lg="5" class="d-none d-lg-block">
          <img class="hero-img" src="../../assets/img/hero-img.png" />
        </b-col>
        <b-col cols="12" lg="7" class="text-left left-col">
          <h1 class="text-uppercase mt-5">
            Do zdravih navad z več pameti kot sreče.
          </h1>
          <b-row class="mb-225 mt-4 mt-lg-3 align-items-center">
            <b-col cols="6" lg="0" class="d-block d-lg-none">
              <img class="hero-img" src="../../assets/img/hero-img.png" />
            </b-col>
            <b-col cols="6" lg="12">
              <h5>
                Pridruži se programu ustvarjanja zdravih navad, osnovanemu na
                nevroznanosti in psihologiji.
              </h5>
            </b-col>
          </b-row>
          <div class="green-bg-wrap">
            <div class="green-bg-stretch"></div>
            <div class="content">
              <p class="text-secondary">
                <b>„Danes pa res začnem,“</b> si rečemo vsak ponedeljek. Pa naj
                gre za vadbo, zdravo prehrano, branje ali kakšno drugo zdravo
                navado.
                <br />
                <br />
                <i> Sploh obstaja učinkovit način uvajanja zdravih navad? </i>
                <br />
                <br />
                Ja. In začne se v možganih. Začnite program ustvarjanja zdravih
                navad, ki smo ga razvili s psihologom, dr. Simonom Brezovarjem,
                na osnovi sodobnih teorij nevroznanosti in psihologije.
              </p>
              <div class="mt-5">
                <b-button
                  variant="danger"
                  class="mr-4 my-1 fixed-width"
                  href="#programi"
                  >ZAČNI ZDAJ</b-button
                >
                <b-button
                  variant="blank"
                  class="my-1 fixed-width"
                  href="#psiholog"
                  >PREBERI VEČ</b-button
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
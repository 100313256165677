<template>
  <div class="section bottle-section">
    <b-container>
      <div class="bottle-container">
        <div class="white-bg"></div>
        <img
          class="bottle-img"
          src="../../assets/img/bottle.png"
          alt="Radenska"
        />
      </div>
      <h2 class="mt-5">NA ZDRAVJE ZDRAVIM NAVADAM!</h2>
      <div class="narrow">
        <h4>
          Zdrave navade se začnejo z Radensko, z višjo vsebnostjo kalcija in
          magnezija.
        </h4>
      </div>
      <hr class="green-dotted mt-4 mb-5" />
      <div class="narrow-2">
        <h6>
          Z 1 litrom naravne mineralne vode Radenska Kraljevi vrelec na dan
          boste zaužili zadostne količine mineralov kalcija in magnezija, ki
          prispevata k:
        </h6>
      </div>

      <b-row class="bubbles-container mt-4 mb-5 pb-5">
        <b-col cols="12" lg="4">
          <div class="bubble b1">
            <img src="../../assets/img/bottle-bubble-imgs/b1.png" />
          </div>
        </b-col>
        <b-col cols="12" lg="4">
          <div class="bubble b2">
            <img src="../../assets/img/bottle-bubble-imgs/b2.png" />
          </div>
        </b-col>
        <b-col cols="12" lg="4">
          <div class="bubble b3">
            <img src="../../assets/img/bottle-bubble-imgs/b3.png" />
          </div>
        </b-col>
      </b-row>
      <p class="text-sm pt-5">
        *Potrjeno s strani Ministrstva za zdravje Republike Slovenije<br />
        (Odločba št. 355-15/2013-15)
      </p>
      <hr class="green-dotted mt-5" />
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
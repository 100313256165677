<template>
  <div class="section programs">
    <b-container id="programi">
      <h2>PROGRAMI</h2>
      <h4>Izberite program. Sodelovanje je popolnoma brezplačno.</h4>
      <hr class="gray-dotted my-4" />
      <div v-for="(program, index) in programs" :key="`${index}pr`">
        <b-row>
          <b-col cols="12" md="4">
            <b-row>
              <b-col cols="5" xl="6" class="text-right">
                <img
                  class="ml-auto mr-0"
                  :src="require(`../../assets/img/programs/p${index + 1}.png`)"
                />
              </b-col>
              <b-col cols="7" xl="6" class="text-left pt-3 pt-sm-4 px-0">
                <h4
                  class="text-uppercase program-title"
                  v-html="program.title"
                ></h4>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="5" class="text-left pt-4 pr-xl-4">
            <p v-html="program.text"></p>
          </b-col>
          <b-col cols="12" md="2" class="pt-4 pt-sm-4 px-0">
            <div class="btns-container">
              <b-button
                variant="danger"
                class="w-100 px-0"
                @click="openApp(program.link, program.qr)"
              >
                ZAČNI Z VIBERJEM
              </b-button>
              <p class="text-success text-sm mt-4 mb-2 ml-3 text-left">
                <i> Nimaš viberja? </i>
              </p>
              <b-button
                variant="gray"
                class="w-100 px-0"
                :href="`/pdf/${program.pdf}`"
                download
                @click="pdfDownloaded(program.qr)"
                >PRENESI PROGRAM</b-button
              >
            </div>
          </b-col>
        </b-row>
        <hr class="gray-dotted w-100 mw-100 my-5" />
        <iframe
          id="lincc"
          width="1"
          height="1"
          style="visibility: hidden"
          sandbox="allow-top-navigation allow-scripts allow-forms"
        ></iframe>
      </div>
    </b-container>
  </div>
</template>

<script>
import { openApp } from "@/mixins/mixins.js";

export default {
  mixins: [openApp],

  data() {
    return {
      programs: [],
    };
  },

  mounted() {
    this.programs = this.$store.state.programs;
  },
};
</script>

<style>
</style>
import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import '@/assets/styles/_import.scss'

import router from './router'
import store from './store'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

import device from "vue-device-detector"
Vue.use(device)

/* import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-KZ9GEN1SW0" },
  enabled: false
}); */

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
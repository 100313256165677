import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    qr_code_to_show: null,
    programs: [
      {
        title: "Korak za korakom do teka",
        text: "Slovenijo že zadnjih nekaj let preplavljajo tekači. Pa ne samo tekači, tudi ljudje, ki zgolj hodijo ali prakticirajo nordijsko hojo. Zmerna aerobna vadba ni samo popularna, ampak je tudi zdrava in ima številne pozitivne vplive tako na telo kot tudi duha. Verjetno po 30 dneh programa še ne bomo sposobni preteči maratona, bomo pa spoznali motivacijske mehanizme ene od najlaže dostopnih aerobnih aktivnosti (hoja, hitra hoja, tek).",
        link: "viber://pa?chatURI=jan-mozgan-tek1",
        qrlink: "https://urlgeni.us/viber/jan-mozgan-tek",
        qr: "jan-mozgan-tek",
        pdf: "Radenska_prirocnik_5.pdf",
      },
      {
        title: "Zdrava komunikacija, zdravi odnosi",
        text: "<span class='weight-med'>Dobri medosebni odnosi v naše življenje vnašajo občutek radosti</span> ter izpolnjenosti, slabi medosebni odnosi pa na drugi strani negativno vplivajo na naše zdravje. S <span class='weight-med'>30-dnevnim programom</span> ne bomo še zreli za diplomatsko službo ali podobno vlogo v družini, navadili pa se bomo na preproste tehnike, ki pomagajo pri razvijanju učinkovitih komunikacijskih veščin.",
        link: "viber://pa?chatURI=jan--mozgan-komunikacija",
        qrlink: "https://urlgeni.us/viber/jan-mozgan-komunikacija",
        qr: "jan-mozgan-komunikacija",
        pdf: "Radenska_prirocnik_2.pdf",
      },
      {
        title: "S sproščenim telesom do sproščenega duha",
        text: "<span class='weight-med'>Čeprav svet okoli sebe procesiramo preko naših čutil in možganov, ga ni dogodka, ki ne bi vplival tudi na naše telesne reakcije</span>. Tekom 30-dnevnega programa še ne bomo dosegli nivoja Bude, bomo pa spoznali najpomembnejše sprostitvene tehnike, ki pomirjajo telesno napetost. ",
        link: "viber://pa?chatURI=jan-mozgan-sproscanje1",
        qrlink: "https://urlgeni.us/viber/jan-mozgan-sproscanje",
        qr: "jan-mozgan-sproscanje",
        pdf: "Radenska_prirocnik_3.pdf",
      },
      {
        title: "Zdravo, zdrava prehrana!",
        text: "Po podatkih Eurostata se vsak drugi Evropejec sooča s preveliko telesno težo. Nezdrava prehrana je gotovo eden glavnih dejavnikov. Zakaj se ljudje prehranjujemo nezdravo, čeprav relativno dobro poznamo načela zdravega prehranjevanja? V 30-dnevnem programu bomo pokukali v zakulisje teh psiholoških pasti ter nezdrave prehranjevalne navade zamenjali z zdravimi.",
        link: "viber://pa?chatURI=jan-mozgan-prehrana1",
        qrlink: "https://urlgeni.us/viber/jan-mozgan-prehrana",
        qr: "jan-mozgan-prehrana",
        pdf: "Radenska_prirocnik_4.pdf",
      },
      {
        title: "Zdravo spanje za prijetne sanje",
        text: "<span class='weight-med'>Spanec je eden ključnih gradnikov (duševnega) zdravja</span>. Nespečnost je večinoma odraz slabih spalnih navad. V tem 30-dnevnem programu bomo ozavestili ključne elemente zdravega spanja in svoje možgane ter telo navadili na boljše spalne navade.",
        link: "viber://pa?chatURI=jan-mozgan-spanje1",
        qrlink: "https://urlgeni.us/viber/jan-mozgan-spanje", // unused
        qr: "jan-mozgan-spanje",
        pdf: "Radenska_prirocnik_1.pdf",
      },
    ],

    prizes: [
      {
        title: "CELODNEVNO RAZVAJANJE ZA DVA V WELLNESSU ORHIDELIA",
      },
      {
        title: "VEČERJA ZA DVA V HIŠI FRANKO",
      },
      {
        title: "ALP GLAMPING VILLAGE VIKEND ODDIH",
      },
      {
        title: "50 x 3 PAKET RADENSKE",
      },
      {
        title: "5 x ŠTARTNINA ZA MARATON TREH SRC",
      },
    ]
  },
  mutations: {
    SET_QR_CODE_TO_SHOW(state, data) {
      state.qr_code_to_show = data
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div>
    <b-modal id="qr-modal" size="lg" hide-footer hide-header>
      <div class="qr-code-container">
        <img
          class="modal-bg-tr"
          :src="require('@/assets/img/modal-bg-tr.png')"
        />
        <img
          class="modal-bg-bl"
          :src="require('@/assets/img/modal-bg-bl.png')"
        />
        <div class="inner-content">
          <h2 class="text-uppercase">Poskeniraj Qr kodo</h2>
          <h4>
            Do vsebine dostopaš na računalniku?<br />
            Nadaljuj s telefonom, tako da poskeniraš QR kodo.
          </h4>
          <hr class="green-dotted my-4" />
          <img
            class="close-btn cursor-pointer"
            @click="$bvModal.hide('qr-modal')"
            :src="require('@/assets/img/close-btn.svg')"
          />
          <img
            v-if="$store.state.qr_code_to_show"
            class="qr-code-img"
            :src="
              require(`@/assets/img/qr-codes/svg_noicon/qr-viber-${$store.state.qr_code_to_show}-medium.svg`)
            "
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
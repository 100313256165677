export const openApp = {

    methods: {
        openApp(url, qr) {
            if (this.$device.mobile) {
                this.trackEvent("zacni_viber_mobile", qr)
                window.location = url;

                var delay = 25;

                var ua = window.navigator.userAgent;
                var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
                var webkit = !!ua.match(/WebKit/i);
                var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

                if (iOSSafari) delay = 4000;

                setTimeout(() => {
                    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
                        window.location =
                            "https://play.google.com/store/apps/details?id=com.viber.voip";
                    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
                        window.location =
                            "https://apps.apple.com/si/app/viber-messenger-chats-calls/id382617920";
                    } else {
                        this.showModal(qr)
                        console.log("showing modal as backup")
                    }
                }, delay);
            } else {
                this.trackEvent("zacni_viber_desktop", qr)
                this.showModal(qr)
            }
        },

        showModal(qr) {
            this.$store.commit("SET_QR_CODE_TO_SHOW", qr);
            this.$bvModal.show("qr-modal");
        },

        pdfDownloaded(qr) {
            this.trackEvent('prenesi_program', qr)
        },

        trackEvent(eventName, program) {
            var head = document.getElementsByTagName("head")[0];
            var script = document.createElement("script");

            script.textContent = `gtag("event", "${eventName}", {program: "${program}"});`;
            head.appendChild(script);
        }
    },
}
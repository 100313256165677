<template>
  <div class="section facts-section">
    <div class="container">
      <b-row class="pb-5">
        <b-col md="6" class="text-left">
          <div class="narrow-1">
            <h3 class="text-uppercase">
              Program osnovan na nevroznanosti in psihologiji
            </h3>
            <h6>
              Vztrajati pri zdravih navadah je težko. Res, res težko. A
              nevroznanost in psihologija poznata učinkovita orodja, ki nam
              lahko pri tem zelo pomagajo.
              <br />
              <span class="weight-med">
                Uporabili jih bomo v programu ustvarjanja zdravih navad in naše
                možgane postopoma učili, kako ne obupati.
              </span>
            </h6>
          </div>
        </b-col>
        <b-col md="6" class="text-center text-md-left">
          <img class="f1-img" src="../../assets/img/facts/f1.png" />
        </b-col>
      </b-row>
      <b-row class="py-5">
        <b-col md="6" order="2" order-md="1">
          <img class="f2-img" src="../../assets/img/facts/f2.png" />
        </b-col>
        <b-col md="6" class="text-left" order="1" order-md="2">
          <div class="narrow-1">
            <h3 class="text-uppercase">30 korakov<br />v 30 dneh</h3>
            <h6>
              Zdrave navade bomo gradili postopoma, saj veš, kamen na kamen.
              Vsak dan te bo v tvojem Viber nabiralniku čakal nasvet.<br />30
              majhnih nasvetov utemeljenih na znanosti pa te bo postopoma
              pripeljalo do velikega cilja: nove zdrave navade.
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-5">
        <b-col md="6" class="text-left">
          <div class="narrow-1">
            <h3 class="text-uppercase">To je<br />Jan Možgan</h3>
            <h6>
              Srčni znanstvenik, ki bo tvoje možgane učil, kako vztrajati pri
              novi zdravi navadi. Pomembno je, da je srce na pravem mestu, nato
              pa se vsaka navada začne v glavi. Zato bo Jan Možgan tisti, ki te
              bo vodil skozi 30-dnevni program ustvarjanja zdravih navad. Vsak
              dan te bo v aplikaciji Viber obvestil o koraku programa, ki je na
              sporedu in ti pojasnil, zakaj je ta korak pomemben!
              <b>Z njegovo pomočjo bo zdrava navada mala malica!</b>
            </h6>
          </div>
        </b-col>
        <b-col md="6" class="text-center text-md-left">
          <img class="f1-img" src="../../assets/img/facts/f3.png" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <div class="section prizes">
    <div class="container">
      <h2 class="text-uppercase">
        NAGRADNA IGRA ZDRAVE NAGRADE PRINAŠAJO NAGRADE JE ZAKLJUČENA!
      </h2>

      <h2 class="text-uppercase pt-5">Nagrajenci:</h2>

      <div class="winners-container pt-5">
        <h4>VEČERJA ZA DVA V HIŠI FRANKO</h4>
        <h6>Barbara Stanič</h6>

        <h4 class="pt-4">ALP GLAMPING VILLAGE VIKEND ODDIH</h4>
        <h6>Milena Hribar</h6>

        <h4 class="pt-4">CELODNEVNO RAZVAJANJE ZA DVA V WELLNESSU ORHIDELIA</h4>
        <h6>Kristina Kračun</h6>

        <h4 class="pt-4">5 X ŠTARTNINA ZA MARATON TREH SRC</h4>
        <h6>
          Andrej Čehtl
          <br />
          Romana Virc
          <br />
          Živa Eva Dobnik
          <br />
          Ksenija Vid
          <br />
          Mitja Senčar
        </h6>

        <h4 class="pt-4">50 X 3 PAKET RADENSKE</h4>

        <h6>
          Samanta Babnik <br />Zala Likar <br />Urša Jernejc <br />Katja Žlebir
          <br />Anja Iskra <br />Ava Tutin <br />Polona Aleksandra Hvala
          <br />Aleš Krevs <br />Metka Lavrič <br />Alenka Avguštin <br />Nina
          Platovšek Košir <br />Urška Tadina <br />Katja Krope <br />Bitenc
          Suzana <br />Sabina Prijatelj <br />Maša Dragorajac <br />Diana Špes
          <br />Tina Lovenjak šumah <br />Barbara Kokot Popović <br />Peter
          Novak <br />Alenka Terziev <br />Katja Čavčić <br />Anja <br />Petra
          Merljak <br />Ana Marovšek <br />Tanja Keršmanc <br />Andreja Tekauc
          Golob <br />Aleksandra Kukovec <br />Letonja Tamara <br />Lidija
          Pugelj <br />Klavdija <br />Špela <br />MARIJA BAŠKARAD <br />Andraž
          Cerar <br />Klavdija Klinar <br />Nina Zupančič <br />Sandra Mole
          <br />Martina Zadravec <br />Alenčica Studnička <br />Sebastijan
          Kavčič <br />Požar <br />Anja Rijavec <br />Tina Groselj <br />Dragan
          Mitić <br />Marjeta Šuper <br />Katja Petac
        </h6>
      </div>

      <div class="narrow pt-4">
        <h6>
          V skladu s pogoji in pravili nagradne igre, bomo končen seznam vseh
          nagrajencev objavili, ko dobimo odziv od vseh nagrajencev.
        </h6>
      </div>
      <hr class="green-dotted my-4" />
      <div class="prizes-container mt-5">
        <div
          class="prize-box"
          v-for="(prize, index) in prizes"
          :key="`${index}pz`"
        >
          <img :src="require(`../../assets/img/prizes/p${index + 1}.png`)" />
          <!-- <img :src="require(`../../assets/img/prizes/p1.png`)" /> -->
          <h3 class="text-dark my-5 px-2 text-uppercase">{{ prize.title }}</h3>
        </div>
      </div>
      <a href="/pogoji.pdf?v=2" target="_blank" class="d-block pt-5 text-dark"
        >Pravila nagradne igre</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prizes: [],
    };
  },
  mounted() {
    this.prizes = this.$store.state.prizes;
  },
};
</script>

<style>
</style>
<template>
  <div class="section program-boxes">
    <b-container>
      <h2>PROGRAMI</h2>
      <h5>Izberite program. Sodelovanje je popolnoma brezplačno.</h5>
      <hr class="gray-dotted my-4" />
      <div class="programs-container">
        <div
          class="program-box"
          v-for="(program, index) in programs"
          :key="`${index}pb`"
        >
          <!-- <img
            class="top-img"
            :src="require(`../../assets/img/program-boxes/p4.png`)"
          /> -->
          <img
            class="top-img"
            :src="require(`../../assets/img/program-boxes/p${index + 1}.png`)"
          />
          <div class="box-inner">
            <h4 class="text-uppercase text-left" v-html="program.title"></h4>
            <div class="btns-container mt-5">
              <b-button
                variant="danger"
                class="w-100 px-0 py-2"
                @click="openApp(program.link, program.qr)"
              >
                ZAČNI Z VIBERJEM
              </b-button>
              <p class="text-success text-sm mt-4 mb-2 ml-3 text-left">
                Nimaš viberja?
              </p>
              <b-button
                variant="gray"
                class="w-100 px-0 py-2"
                :href="`/pdf/${program.pdf}`"
                download
                @click="pdfDownloaded(program.qr)"
              >
                PRENESI
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <hr class="green-dotted my-5" />
    </b-container>
  </div>
</template>

<script>
import { openApp } from "@/mixins/mixins.js";

export default {
  mixins: [openApp],

  data() {
    return {
      programs: [],
    };
  },

  mounted() {
    this.programs = this.$store.state.programs;
  },
};
</script>

<style>
</style>
<template>
  <div>
    <hero-section />
    <video-section />
    <programs-section />
    <facts-section />
    <how-it-works-section />
    <prizes-section class="d-none" />
    <program-boxes-section />
    <bottle-section />
    <QRModal />
    <vue-cookie-accept-decline
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <!-- Optional -->
      <div slot="message">
        Spletna stran uporablja piškotke za zagotavljanje boljše uporabniške
        izkušnje in spremljanje statistike obiska.
        <a href="/piskotki.pdf" target="_blank">Več o piškotkih</a>.
      </div>

      <!-- Optional -->
      <div slot="declineContent">ZAVRNI</div>

      <!-- Optional -->
      <div slot="acceptContent">SPREJMI</div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
import HeroSection from "@/components/sections/Hero.vue";
import VideoSection from "@/components/sections/Video.vue";
import ProgramsSection from "@/components/sections/Programs.vue";
import FactsSection from "@/components/sections/Facts.vue";
import HowItWorksSection from "@/components/sections/HowItWorks.vue";
import PrizesSection from "@/components/sections/Prizes.vue";
import ProgramBoxesSection from "@/components/sections/ProgramBoxes.vue";
import BottleSection from "@/components/sections/Bottle.vue";
import QRModal from "@/components/QRModal.vue";

export default {
  components: {
    HeroSection,
    VideoSection,
    ProgramsSection,
    FactsSection,
    HowItWorksSection,
    PrizesSection,
    ProgramBoxesSection,
    BottleSection,
    QRModal,
  },

  mounted() {
    this.addUA();
  },

  methods: {
    cookieStatus(status) {
      if (status === "accept") {
        this.addToHead();
      }
    },

    cookieClickedAccept() {
      this.addToHead();
    },

    cookieClickedDecline() {
      console.log("declined");
    },

    addToHead() {
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-KZ9GEN1SW0";
      head.appendChild(script);

      script = document.createElement("script");
      script.textContent =
        "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-KZ9GEN1SW0');";
      head.appendChild(script);
    },

    addUA() {
      let validityInterval = Math.round(new Date() / 1000 / 3600 / 24 / 4);
      let clientIDSource =
        window.location.host +
        ";" +
        navigator.userAgent +
        ";" +
        navigator.language +
        ";" +
        validityInterval;
      let clientIDHashed = this.cyrb53(clientIDSource).toString(16);

      var UACode = "UA-220556021-1";

      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.textContent = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','//www.google-analytics.com/analytics.js','ga'); ga('create', '${UACode}', { 'storage': 'none', 'clientId': '${clientIDHashed}' }); ga('set', 'anonymizeIp', true); ga('send', 'pageview');`;
      head.appendChild(script);
    },

    cyrb53(str, seed = 0) {
      let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
      for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
      }
      h1 =
        Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
        Math.imul(h2 ^ (h2 >>> 13), 3266489909);
      h2 =
        Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
        Math.imul(h1 ^ (h1 >>> 13), 3266489909);
      return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    },
  },
};
</script>

<template>
  <div class="section how-it-works">
    <div class="bg-left"></div>
    <div class="bg-right"></div>
    <div class="container">
      <h2 class="text-light text-uppercase">Kako deluje program?</h2>
      <hr class="gray-dotted o-05 my-4" />
      <div class="steps-container mt-5">
        <div class="left text-right">
          <div class="first-content">
            <h3 class="text-uppercase text-light">
              Prijavi se z<br />aplikacijo Viber
            </h3>
            <h6>
              Preko aplikacije Viber boš naslednjih 30 dni prejemal nasvete Jana
              Možgana, virtualnega znanstvenika, ki te bo vodil skozi program.
              Če nimaš Viberja, lahko program tudi enostavno
              <a href="#programi">preneseš</a>.
            </h6>
          </div>

          <div class="second-content d-block d-lg-none mt-5 mt-lg-0">
            <h3 class="text-uppercase text-light">En korak na dan</h3>
            <h6>
              Potem ko se prijaviš v program, v svoj Viber nabiralnik vsak dan
              prejmeš en nasvet.
            </h6>
          </div>
        </div>
        <div class="mid text-center">
          <img src="../../assets/img/steps2.png" />
        </div>
        <div class="right text-left d-none d-lg-block">
          <div class="content">
            <h3 class="text-uppercase text-light">En korak na dan</h3>
            <h6>
              Potem ko se prijaviš v program, na izbran kanal vsak dan prejmeš
              en nasvet. 30 nasvetov ti bo v 30 dneh pomagalo utrditi novo
              zdravo navado.
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>